import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import MapState from '../states/map'

const Wrapper = styled.canvas`
	background-image: url('${ ({ src }) => src }');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	width: 100%;
	height: 100%;
`

const MapCanvas = ({ children, ...rest }) =>
{
	const { imageURL } = MapState

	return <Wrapper src={ imageURL } { ...rest }></Wrapper>
}

export default observer(MapCanvas)