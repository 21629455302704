import { makeObservable, observable, computed, action, reaction } from 'mobx'
import { isNumber, getSegmentsInRange, getClosestSegmentIndex, getRemoteImageSize } from '../utility'
import 'regenerator-runtime/runtime'

class MapState
{
	// Scaling
	@observable width = 0
	@observable height = 0
	@observable minimumScale = 0.25
	@observable maximumScale = 2
	@observable defaultScale = 1
	@observable scaleOptionsAmount = 4
	@observable scaleOptions = getSegmentsInRange(this.scaleOptionsAmount, this.minimumScale, this.maximumScale)
	@observable prescale = 0
	@observable scaledWidth = 0
	@observable scaledHeight = 0
	@observable scale = this.defaultScale

	// UI
	@observable imageURL = 'https://i.redd.it/5fzec0bg7vn31.jpg'
	@observable isContextMenuVisible = false
	@observable isDragging = false
	@observable viewportRef = null

	@computed get canIncreaseScale () { return this.scale < this.scaleOptions[ this.scaleOptions.length - 1 ] }
	@computed get canDecreaseScale () { return this.scale > this.scaleOptions[ 0 ] }

	@action initialize ()
	{
		this.updateOriginalSize()
		this.updatePrescale()
		this.updateScaledSize()

		reaction(() => this.imageURL, () => { this.updateOriginalSize() })
		reaction(() => [ this.viewportRef, this.width, this.height ], () => { this.updatePrescale() })
		reaction(() => [ this.width, this.height, this.prescale, this.scale ], () => { this.updateScaledSize() })
	}

	@action async updateOriginalSize ()
	{
		const { width, height } = await getRemoteImageSize(this.imageURL)
		this.width = width
		this.height = height
	}

	@action setImageURL = ((value) =>
	{
		if (!value || typeof value !== 'string') throw new Error('Image URL is invalid.')
		this.imageURL = value
	}).bind(this)

	@action setWidth = ((value) =>
	{
		if (!isNumber(value)) throw new Error('Width value must be a number.')
		this.width = value
	}).bind(this)

	@action setHeight = ((value) =>
	{
		if (!isNumber(value)) throw new Error('Height value must be a number.')
		this.height = value
	}).bind(this)

	@action increaseScale = (() =>
	{
		if (!this.scaleOptions || this.scaleOptions.length === 0) return
		const index = getClosestSegmentIndex(this.scaleOptions, this.scale)
		if (index === -1 || index >= this.scaleOptions.length - 1) return
		this.scale = this.scaleOptions[ index + 1 ]
	}).bind(this)

	@action decreaseScale = (() =>
	{
		if (!this.scaleOptions || this.scaleOptions.length === 0) return
		const index = getClosestSegmentIndex(this.scaleOptions, this.scale)
		if (index === -1 || index <= 0) return
		this.scale = this.scaleOptions[ index - 1 ]
	}).bind(this)

	@action setIsDragging = ((value) =>
	{
		this.isDraggin = value ? true : false
	}).bind(this)

	@action setIsContextMenuVisible = ((value) =>
	{
		this.isContextMenuVisible = value ? true : false
	}).bind(this)

	@action setViewportRef = ((value) =>
	{
		if (!value) throw new Error('Invalid DOM element value.')
		this.viewportRef = value
	}).bind(this)

	@action updatePrescale = (() =>
	{
		if (!this.viewportRef?.current || !this.height || !this.width) return
		this.prescale = Math.min(this.viewportRef.current.clientHeight / this.height, this.viewportRef.current.clientWidth / this.width) || 1
	}).bind(this)

	@action updateScaledSize = (() =>
	{
		this.scaledWidth = (this.width * (this.prescale || 0) * (this.scale || 0)) || 1
		this.scaledHeight = (this.height * (this.prescale || 0) * (this.scale || 0)) || 1
	}).bind(this)

	constructor ()
	{
		makeObservable(this)
		this.initialize()
	}
}

export default new MapState()