import React, { Fragment, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import MapScaler from './MapScaler'
import MapGrid from './MapGrid'
import MapCanvas from './MapCanvas'
import MapState from '../states/map'

const Wrapper = styled(motion.div)`
	position: absolute;
	overflow: hidden;
	display: grid;
	border-radius: var(--rpg-border-radius);
	transition: width 150ms, height 150ms;
	width: ${ ({ width }) => width }px;
	height: ${ ({ height }) => height }px;
`

const Map = () =>
{
	const { viewportRef, scaledWidth, scaledHeight } = MapState

	const onDragStart = () => { }
	const onDragEnd = () => { }

	return (
		<Fragment>
			<Wrapper drag style={ { originX: 0, originY: 0 } } dragConstraints={ viewportRef } width={ scaledWidth } height={ scaledHeight } onDragStart={ onDragStart } onDragEnd={ onDragEnd }>
				<MapCanvas />
				<MapGrid columns={ 10 } rows={ 10 } />
			</Wrapper>
			<MapScaler />
		</Fragment>
	)
}

export default observer(Map)