export const getRemoteImageSize = (url) => new Promise((resolve, reject) =>
{
	if (!url) reject('Invalid URL.')
	let img = document.createElement('img')
	img.onload = function ()
	{
		if (isNumber(this.width) && isNumber(this.height)) resolve({ width: this.width, height: this.height })
		img = null
	}
	img.onerror = () => { reject('Image failed to load.') }
	img.src = url
})

export const getLimitedNumber = (value, min, max) =>
{
	if (!isNumber(value)) throw 'Invalid value to limit.'
	if (!isNumber(min)) throw 'Invalid minimum limit.'
	if (!isNumber(max)) throw 'Invalid maximum limit.'

	if (value < min) return min
	else if (value > max) return max
	else return value
}

export const getSegmentsInRange = (segmentsAmount, min, max) =>
{
	if (!isNumber(min)) throw 'Invalid minimum range.'
	if (!isNumber(max)) throw 'Invalid maximum range.'
	if (!isNumber(segmentsAmount)) throw 'Invalid segments amount.'
	if (segmentsAmount <= 0) throw 'Segments cannot be negative or zero.'
	if (segmentsAmount <= 1) throw 'Too few segments.'

	const segments = [ min, ...new Array(segmentsAmount - 2), max ]
	const average = (max - min) / segmentsAmount
	for (let index = 1; index < segmentsAmount - 1; index++)
	{
		segments[ index ] = min + average * index
	}
	return segments
}

export const getClosestSegmentIndex = (segments, value) =>
{
	if (!isNumber(value)) throw 'Invalid value.'
	if (segments === null || !Array.isArray(segments) || segments.length === 0) throw 'Invalid segments.'

	const gap = segments[ 1 ] - segments[ 0 ]
	for (let index = 0; index < segments.length; index++)
	{
		const segment = segments[ index ]
		if (index === 0 && value <= segment) return index
		else if (index === segments.length - 1 && value >= segment) return index
		else if (isSegmentWithinRange(segment, value, gap)) return index
	}
	return -1
}

const isSegmentWithinRange = (segment, value, gap) =>
{
	return (value <= segment + gap / 2) && (value >= segment - gap / 2)
}

export const isNumber = (value) => !isNaN(value) && value !== null && value !== Infinity