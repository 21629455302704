import { createGlobalStyle } from 'styled-components'
import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import "regenerator-runtime/runtime.js"

const GlobalStyle = createGlobalStyle`
	html {
		overflow: hidden;
	}

	:root {
		--rpg-border-radius: 0.5rem;
	}
`

ReactDOM.render(<Fragment><App /><GlobalStyle /></Fragment>, document.getElementById('app'))