import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { Droppable } from 'react-beautiful-dnd'

const Wrapper = styled.div`
	display: grid;
	grid: 100% / 100%;
`
const Container = styled.div`
	box-sizing: border-box;
	display: grid;
`

const MapGridCell = forwardRef(({ id, children, ...rest }, ref) =>
{
	return (
		<Wrapper { ...rest } ref={ ref }>
			<Droppable droppableId={ id }>
				{ provided => <Container { ...provided.droppableProps } ref={ provided.innerRef }>{ children }</Container> }
			</Droppable>
		</Wrapper>
	)
})

export default MapGridCell