import React, { useState } from 'react'
import styled from 'styled-components'
import MapGridCell from './MapGridCell'

const DEFAULT_COLUMNS = 10, DEFAULT_ROWS = 10

const CustomMapGridCell = styled(MapGridCell)`
	background-color: rgba(255, 255, 255, 0.1);
	transition: background-color 150ms;

	&:hover {
		background-color: rgba(255, 255, 255, 0.3);
	}
`
const Wrapper = styled.div`
	position: absolute;
	display: grid;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: opacity 150ms;
	grid-template-columns: repeat(${ ({ columns }) => columns || DEFAULT_COLUMNS }, 1fr);
	grid-template-rows: repeat(${ ({ rows }) => rows || DEFAULT_ROWS }, 1fr);
	--cell-border: 1px solid rgba(0, 0, 0, 0.15);

	&:hover {
		opacity: 1;
	}

	> ${ CustomMapGridCell }
	{
		box-sizing: border-box;
		border-top: var(--cell-border);
		border-left: var(--cell-border);
	}

	// All right-most cells
	> ${ CustomMapGridCell }:nth-child(${ ({ columns }) => columns || DEFAULT_COLUMNS }n)
	{
		border-right: var(--cell-border);
	}

	// All bottom cells
	> ${ CustomMapGridCell }:nth-last-child(-n+${ ({ columns }) => columns || DEFAULT_COLUMNS })
	{
		border-bottom: var(--cell-border);
	}
`

const MapGrid = ({ columns, rows }) =>
{
	const [ rowsMatrix, setRowsMatrix ] = useState(new Array(rows).fill(new Array(columns).fill(0)))

	return (
		<Wrapper columns={ columns } rows={ rows }>
			{ rowsMatrix && rowsMatrix.flat(2).map((value, index) => <CustomMapGridCell key={ `cell-${ index }` } id={ `cell-${ index }` } />) }
		</Wrapper>
	)
}

export default MapGrid