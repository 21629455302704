import React, { useRef, useEffect } from 'react'
import { motion } from 'framer-motion'
import { DragDropContext } from 'react-beautiful-dnd'
import { observer } from 'mobx-react-lite'
import Map from './Map'
import styled from 'styled-components'
import MapState from '../states/map'

const Wrapper = styled.div`
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	display: grid;
	grid-template: 100% / 100%;
	padding: 1rem;
	background-color: hsl(0, 0%, 90%);
`
const Container = styled(motion.div).attrs(() => ({ className: 'drag-area' }))`
	position: relative;
`

const App = () =>
{
	const viewportRef = useRef(null)

	useEffect(() => { MapState.setViewportRef(viewportRef) }, [ viewportRef ])

	return (
		<Wrapper>
			<Container ref={ viewportRef }>
				<DragDropContext>
					<Map />
				</DragDropContext>
			</Container>
		</Wrapper>
	)
}

export default observer(App)