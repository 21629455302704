import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import MapState from '../states/map'

const Button = styled.button`
	border-radius: 50%;
	background-color: hsl(0, 0%, 85%);
	z-index: 1;
	width: 24px;
	height: 24px;
	outline: none !important;
	transition: box-shadow 150ms;

	&:active, &:focus {
		box-shadow: inset 0 0 0 2px blue;
	}
`
const Wrapper = styled.div`
	border-radius: var(--rpg-border-radius);
	background-color: hsl(0, 0%, 80%);
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: max-content;
	align-items: center;
	gap: 0.5rem;
	position: absolute;
	top: 0;
	right: 0;
	padding: 0.5rem;
`
const Title = styled.h6``

const MapScaler = () =>
{
	const { canDecreaseScale, canIncreaseScale, increaseScale, decreaseScale } = MapState

	return (
		<Wrapper>
			<Title>Map Scale</Title>
			<Button onClick={ decreaseScale } disabled={ !canDecreaseScale }>-</Button>
			<Button onClick={ increaseScale } disabled={ !canIncreaseScale }>+</Button>
		</Wrapper>
	)
}

export default observer(MapScaler)